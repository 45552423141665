<template>
  <navbar-home></navbar-home>

  <!-- Section 1 -->
  <section class="space-3 mt-5 homepage-box">
    <div class="container">
      <div class="row align-items-center justify-content-around">
        <div class="col-lg-6 pr-lg-5 pt-3 text-center text-md-left">
          <h1 class="display-5 font-weight-bold line-height-1" data-aos="fade-left">
            The simplest way to keep track of stuff!</h1>
          <p class="h5 font-weight-medium mt-4" data-aos="fade-left" data-aos-delay="100">
            As easy as replying to an email.</p>
          <p class="h5 font-weight-normal mt-4" data-aos="fade-left" data-aos-delay="100">
            Do you want to journal and keep track of things you are working on?</p>
          <p class="h5 font-weight-normal mt-4" data-aos="fade-left" data-aos-delay="100">
            Simply reply to an email message we send you daily.
          </p>
          <div class="mt-4" data-aos="fade-left" data-aos-delay="200">
            <router-link
               to="/signup"
               class="d-inline-flex align-items-center btn btn-primary mb-2 mb-md-0">
              Sign up for free now!
              <i class="fa fa-angle-right ri-lg ml-2"></i>
            </router-link>
            <p class="text-muted mt-1"><small>No credit card required.</small></p>
          </div>
        </div>
        <div class="col-lg-6 text-center" data-aos="fade-up-right"
             data-aos-duration="1000" data-aos-easing="ease-out-back"
             data-aos-delay="200">
          <img class="hero-image img-fluid"
               src="../assets/img/home/rocket_circle.png" alt="Image" />
        </div>
      </div>
    </div>
  </section>
  <!-- End Section 1 -->

  <!-- Section 2 -->
  <section class="space-3 bg-light">
    <div class="container">
      <div class="w-100 w-lg-75 text-center mx-auto mb-5">
        <i class="fa fa-feather-alt fa-3x"></i>
        <h2 class="font-weight-bold text-center mt-4">Journal everyday without
          spending the effort to remember</h2>
      </div>
      <div class="row text-center">
        <div class="col-sm-6 col-md-3 my-3 my-md-0">
          <div class="card card-body hover-translate-y hover-shadow">
            <h2 class="h1 font-weight-bold">1</h2>
            <p class="font-weight-medium">Set up your schedule!</p>
          </div>
        </div>
        <div class="col-sm-6 col-md-3 my-3 my-md-0">
          <div class="card card-body hover-translate-y hover-shadow">
            <h2 class="h1 font-weight-bold">2</h2>
            <p class="font-weight-medium">Receive reminder email!</p>
          </div>
        </div>
        <div class="col-sm-6 col-md-3 my-3 my-md-0">
          <div class="card card-body hover-translate-y hover-shadow">
            <h2 class="h1 font-weight-bold">3</h2>
            <p class="font-weight-medium">Reply to said email!</p>
          </div>
        </div>
        <div class="col-sm-6 col-md-3 my-3 my-md-0">
          <div class="card card-body hover-translate-y hover-shadow">
            <h2 class="h1 font-weight-bold">
              <i class="fal fa-hands-wash"></i>
            </h2>
            <p class="font-weight-medium">Boom! There is no step 4!</p>
          </div>
        </div>
      </div>
      <div class="text-center mt-5">
        <router-link to="/signup"
           class="d-inline-flex align-items-center btn btn-primary">
          Let's get started
          <i class="fa fa-angle-right ri-lg ml-2"></i>
        </router-link>
      </div>
    </div>
  </section>
  <!-- End Section 2 -->

  <!-- Section 3 -->
  <section class="space-3">
    <div class="container">
      <div class="w-100 w-lg-75 text-center mx-auto mb-5">
        <i class="fa fa-user-ninja fa-3x"></i>
        <h2 class="font-weight-bold mt-4">What are we using trbo.io for?</h2>
      </div>

      <!-- Work tracking -->
      <div class="row align-items-center justify-content-around">
        <div class="col-lg-6" data-aos="fade-right">
          <img class="img-fluid rounded-lg" src="../assets/img/home/presentation.png"
               alt="Image" data-zoomable>
        </div>
        <div class="col-lg-5 mt-4 mt-lg-0" data-aos="fade-left">
          <h4 class="font-weight-bolder">Tracking work for perf reviews</h4>
          <p class="mt-2 mb-4">
            &ldquo;I have quarterly performance reviews and I am generally
            scampering towards the end trying to remember and track what I worked on.</p>
          <p>
            Instead, now, I receive an email everyday at end-of-play and just track my work
            there. Much easier to get to this list later.&rdquo;
          </p>
        </div>
      </div>

      <!-- Micro journaling -->
      <div class="row align-items-center justify-content-around py-1 py-md-5">
        <div class="col-lg-5 mt-4 mt-lg-0" data-aos="fade-left">
          <h4 class="font-weight-bolder">Micro journaling // project progress</h4>
          <p class="mt-2 mb-4">
            &ldquo;Small updates to remember how my projects are shaping up
            and what I spent my day working on.</p>
          <p>
            It works quite well and I like the ability to reply to an email (from desktop or
            mobile) instead of having to open up a website / journaling tool to track this.&rdquo;
          </p>
        </div>
        <div class="col-lg-6" data-aos="fade-right">
          <img class="img-fluid rounded-lg mt-3 mt-md-0" src="../assets/img/home/architect.png"
               alt="Image" data-zoomable>
        </div>
      </div>

      <!-- Personal accountability -->
      <div class="row align-items-center justify-content-around mt-4 py-1 py-md-5 mt-md-0">
        <div class="col-lg-6" data-aos="fade-right">
          <img class="img-fluid rounded-lg" src="../assets/img/home/college_student.png"
               alt="Image" data-zoomable>
        </div>
        <div class="col-lg-5 mt-4 mt-lg-0" data-aos="fade-left">
          <h4 class="font-weight-bolder">Personal accountability</h4>
          <p class="mt-2 mb-4">
            &ldquo;Guilt-tripping myself to actually study for my exams.</p>
          <p>
            I have two reminders set daily. One arrives at 7 in the morning and I put in what I
            <em>intend</em> to study today. The second one arrives at 10 at night in which I write
            what I actually got done.</p>
          <p>Not exactly a silver bullet but the guilt of not having enough to report definitely
            helps a bit. :)
          &rdquo;
          </p>
        </div>
      </div>

      <!-- Gratitude Journaling -->
      <div class="row align-items-center justify-content-around py-1 py-md-5">
        <div class="col-lg-5 mt-4 mt-lg-0" data-aos="fade-left">
          <h4 class="font-weight-bolder">Journaling // gratitude journal</h4>
          <p class="mt-2 mb-4">
            &ldquo;Maintaining a gratitude journal. I have been meaning to do this regularly
            but always end up forgetting after a bit.</p>
          <p>
            Here, replying to the Telegram message seems more natural. Have been journaling
            8 days so far. Let's see how far I can take this...&rdquo;
          </p>
        </div>
        <div class="col-lg-6" data-aos="fade-right">
          <img class="img-fluid rounded-lg" src="../assets/img/home/wfh.png"
               alt="Image" data-zoomable>
        </div>
      </div>
    </div>
  </section>
  <!-- End Section 3 -->

</template>

<script>
import AOS from 'aos';
import NavbarHome from '@/components/NavbarHome.vue';

export default {
  name: 'Home',
  components: { NavbarHome },
  mounted() {
    AOS.init();
  },
  methods: {
  },
};
</script>
