// jquery
import 'jquery/dist/jquery';

// bootstrap
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min';

// Findeas
import './assets/css/findeas.min.css';

// Font awesome
import './lib/fontawesome-pro/css/all.css';

// Vex dialog
import 'vex-js/dist/css/vex.css';
import 'vex-js/dist/css/vex-theme-os.css';

// Flatpickr datetime
import 'flatpickr/dist/flatpickr.min.css';
import 'flatpickr/dist/themes/dark.css';

// AOS
import 'aos/dist/aos.esm';
import 'aos/dist/aos.css';

import 'moment';
import 'moment-timezone';

/*
 * This has caused me a LOT of grief. The ordering of these files is important - else things go
 * hay wire. We need the base libraries loaded first -- so jQuery, Bootstrap, findeas, etc.
 *
 * Then the app needs to be loaded - which loads our trbo.scss
 *
 * And then finally we need to load libraries which build on these.
 *
 * So Toastr messes around big time with bootstrap's toast -- which we don't need. So this needs
 * to be loaded at the end.
 */

import { createApp } from 'vue';
import App from './App.vue';

// Toastr
import 'toastr/build/toastr.min.css';

import router from './router';

createApp(App).use(router).mount('#app');
