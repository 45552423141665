import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';

// eslint-disable-next-line import/no-cycle
import auth from '../app/auth';
import UserInfoStore from '../app/user-info-store';

async function requireAuth(to, from, next) {
  if (UserInfoStore.state.loggedIn === true) {
    next();
    return;
  }

  if (!(await auth.isUserSignedIn())) {
    UserInfoStore.setLoggedIn(false);
    next({
      path: '/login',
      query: { redirect: to.fullPath },
    });
  } else {
    UserInfoStore.setLoggedIn(true);
    next();
  }
}

const routes = [
  {
    path: '/login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/signup',
    component: () => import('../views/SignUp.vue'),
  },
  {
    path: '/forgot',
    component: () => import('../views/ForgotPassword.vue'),
  },
  {
    path: '/password_reset/:id/:token',
    component: () => import('../views/PasswordReset.vue'),
  },
  {
    path: '/logout',
    component: () => import('../views/LogoutSuccess.vue'),
    async beforeEnter(to, from, next) {
      await auth.logout();
      next();
    },
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/setup',
    name: 'Setup',
    component: () => import('../views/Onboarding.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/password',
    name: 'Change Password',
    component: () => import('../views/Password.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/Privacy.vue'),
  },
  {
    path: '/terms',
    name: 'Terms of Service',
    component: () => import('../views/TermsOfService.vue'),
  },
  {
    path: '/verify/:email/:code',
    name: 'Verify',
    component: () => import('../views/Verify.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
