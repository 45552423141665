<template>
  <!-- Header -->
  <header>
    <!-- Navbar -->
    <nav class="navbar fixed-top navbar-border navbar-expand-lg navbar-light bg-white">
      <div class="container">
        <!-- Logo -->
        <a class="navbar-brand order-lg-1 flex-grow-1" href="/" aria-label="Logo">
          <img class="logo d-flex" src="@/assets/img/brand/logo.svg" alt="Logo" />
        </a>
        <!-- End Logo -->

        <!-- Navbar Action Button -->
        <div class="d-none d-md-flex align-items-center order-2 order-lg-2
        justify-content-end mr-3 mr-lg-0">
          <router-link to="/signup" class="btn btn-primary d-inline-flex align-items-center">
            <i class="fal fa-rocket-launch"></i>
            <span class="pl-2">Sign Up</span>
          </router-link>
        </div>
        <!-- End Navbar Action Button -->

        <!-- Navbar Toggler / Humberger Menu -->
        <button class="navbar-toggler d-lg-none d-flex align-items-center order-3 order-lg-3"
                type="button" data-toggle="collapse" data-target="#navbar"
                aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
          <i class="fa fa-bars"></i>
        </button>
        <!-- End Navbar Toggler / Humberger Menu -->

        <!-- Navbar Menu -->
        <div class="collapse navbar-collapse order-3 order-lg-1 mr-lg-3" id="navbar">
          <ul class="navbar-nav ml-auto">
            <!-- Nav Item -->
            <li class="nav-item dropdown position-relative d-md-none">
              <router-link to="/signup" class="nav-link"
                           role="button" aria-haspopup="true" aria-expanded="false">
                Sign Up
              </router-link>
            </li>
            <!-- End Nav Item -->
            <!-- Nav Item -->
            <li class="nav-item dropdown position-relative">
              <router-link class="nav-link" to="/login"
                 aria-haspopup="true" aria-expanded="false">
                Login
              </router-link>
            </li>
            <!-- End Nav Item -->

          </ul>
        </div>
        <!-- End Navbar Menu -->
      </div>
    </nav>
    <!-- End Navbar -->
  </header>
  <!-- End Header -->
</template>

<script>
</script>
