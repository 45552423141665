const state = {
  loggedIn: false,
  loadingState: true,
  errorLoadingState: false,
};

// Sets logged in value
const setLoggedIn = (newValue) => {
  state.loggedIn = newValue;
};

const setLoggedOut = () => {
  state.loggedIn = false;
  state.cognitoInfo = {};
};

export default {
  state,
  setLoggedIn,
  setLoggedOut,
};
