<template>
  <router-view/>

  <!-- Footer -->
  <!-- Footer -->
  <footer class="mt-4 pb-2 pt-2">
    <div class="container py-2 border-top">
      <div class="row flex-column flex-lg-row align-items-center
      justify-content-center justify-content-lg-between text-center text-lg-left">
        <div class="col-auto">
          <div class="d-flex flex-column flex-sm-row align-items-center text-small">
            <div>
              <small>&copy; 2020-21 trbo.io.
                <i class="fas fa-sparkles"></i>
                {{createdByText}}
                <a href="https://saurabhj.com" target="_blank">
                  saurabhj</a>.
                All right reserved.</small>
            </div>
          </div>
        </div>
        <div class="col-auto">
          <div class="d-flex flex-column flex-sm-row align-items-center text-small">
            <div>
              <small>
                <router-link to="/privacy" target="_blank">Privacy Policy</router-link>
                &nbsp;|&nbsp;
                <router-link to="/terms" target="_blank">Terms of Service</router-link>
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- End Footer -->
</template>

<style lang="scss">
  @import "@/assets/scss/trbo.scss";
</style>

<script>
import Amplify, { Auth } from 'aws-amplify';

Amplify.configure({
  Auth: {
    region: process.env.VUE_APP_COGNITO_REGION,
    userPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
    mandatorySignIn: false,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    oauth: {
      domain: process.env.VUE_APP_COGNITO_APP_DOMAIN,
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: process.env.VUE_APP_COGNITO_REDIRECT_URI,
      redirectSignOut: process.env.VUE_APP_COGNITO_REDIRECT_URI_SIGNOUT,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
});

// You can get the current config object
Auth.configure();

export default {
  data() {
    return {
      centerFooterRoutes: ['Home'],
      createdByText: 'Created by',
    };
  },
  mounted() {
    const createdByTextOptions = [
      'Created by', 'Forged by', 'Built by', 'Invented by', 'Constructed by',
      'Brought into existence by',
    ];

    const idx = new Date().getSeconds() % createdByTextOptions.length;
    this.createdByText = createdByTextOptions[idx];
  },
};
</script>
