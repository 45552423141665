/* eslint-disable */
import { Auth } from 'aws-amplify';
import { StorageHelper } from 'amazon-cognito-auth-js';
import IndexRouter from '../router/index';
import UserInfoStore from './user-info-store';
import axios from 'axios';
import utils from '@/app/utils';

const COGNITO_CLIENT_ID = process.env.VUE_APP_COGNITO_CLIENT_ID;

const keyPrefix = 'CognitoIdentityServiceProvider.' + COGNITO_CLIENT_ID;

async function getUserInfoStorageKey()  {
  const user = await Auth.currentUserInfo();
  const tokenUserName = user.username;
  return keyPrefix + '.' + tokenUserName + '.userData';
}

async function getUserIdTokenStorageKey() {
  const user = await Auth.currentUserInfo();
  const tokenUserName = user.username;
  return keyPrefix + '.' + tokenUserName + '.idToken';
}

async function getAccessTokenStorageKey() {
  const user = await Auth.currentUserInfo();
  const tokenUserName = user.username;
  return keyPrefix + '.' + tokenUserName + '.accessToken';
}

async function getRefreshTokenStorageKey() {
  const user = await Auth.currentUserInfo();
  const tokenUserName = user.username;
  return keyPrefix + '.' + tokenUserName + '.refreshToken';
}

const storageHelper = new StorageHelper();
const storage = storageHelper.getStorage();

async function getUserIdToken() {
  const keyPrefix = await getUserIdTokenStorageKey();
  return storage.getItem(keyPrefix);
}

async function getAccessToken() {
  const keyPrefix = await getAccessTokenStorageKey();
  return storage.getItem(keyPrefix);
}

/***
 * Calls the ping end point to check if the token is valid.
 * @param token
 * @return {Promise<any>}
 */
async function checkPing(token) {
  const url = `${process.env.VUE_APP_API_URL}/user/ping`;
  const result = axios.get(url, utils.getAxiosConfig(token, false));

  return (await result).data;
}

/** *
 * Logs in a user.
 *
 * @param email
 * @param password
 * @param redirectUrl
 * @return {Promise<void>}
 */
async function loginUser(email, password, redirectUrl) {
    await Auth.signIn(email, password);
    UserInfoStore.setLoggedIn(true);
    if (redirectUrl) {
      await IndexRouter.push(redirectUrl);
    } else {
      await IndexRouter.push('/Dashboard');
    }
}

/** *
 * Logs out user and clears storage
 * @return {Promise<void>}
 */
async function logout() {
  if (await this.isUserSignedIn()) {
    await storage.removeItem(await getUserInfoStorageKey());

    await Auth.signOut({ global: true });

    await storage.removeItem('expires_in');
    await storage.removeItem('id_token');
    await storage.removeItem('access_token');
  }
}

/** *
 * Checks if a user is logged in
 * @return {Promise<boolean>}
 */
async function isUserSignedIn() {
  return (await Auth.currentUserInfo()) != null;
}

export default {
  checkPing,
  isUserSignedIn,
  loginUser,
  logout,
  getUserIdToken,
  getAccessToken,
}
